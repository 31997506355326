import { Box, Button, Heading, Text } from "grommet";
import { Page } from "../shared/Page";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { importRecipeFromURL } from "../recipe/service";
import { useSessionData } from "../auth/useSessionData";
import { getAvailableSitesString } from "../util/recipes";

export const SharedRecipe = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const session = useSessionData();

  const fromSharedUrl = async (url) => {
    try {
      const res = await importRecipeFromURL({ url });
      if (res.error) {
        console.error(res.error);
        const availableSites = getAvailableSitesString("eller");
        setError(
          `Kunde inte importera recept. Testa ett annat från ${availableSites}.`
        );
      } else {
        const { id } = res;
        navigate(`/recipes/${id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (session && session.loading) {
      return;
    }
    const url = searchParams.get("url");

    if (url) {
      if (!session?.isValid) {
        const redirectUrl = `/shared-recipe?url=${url}`;
        navigate(`/login?redirectTo=${encodeURIComponent(redirectUrl)}`);
      } else {
        fromSharedUrl(url);
      }
    } else {
      setError("Ogiltig länk");
    }
  }, [session, searchParams]);

  return (
    <Page>
      <Box align="center">
        {error && (
          <>
            <Heading level="3" as="h1" textAlign="center">
              Något gick fel
            </Heading>
            <Text textAlign="center" margin={{ bottom: "small" }}>
              {error}
            </Text>
            <Button href="/" label="Till Gurkan" />
          </>
        )}
      </Box>
    </Page>
  );
};
