import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Layer,
  Heading,
  TextInput,
  Form,
  FormField,
  TextArea,
  Card,
} from "grommet";
import { Add, Close, FormAdd, FormSubtract } from "grommet-icons";
import { token } from "../auth/util";
import { addRecipe } from "./service";
import IngredientSearchField from "../ingredient/search";
import { EditIngredientModal } from "../admin/editIngredientModal";
import { ImportOrCreateRecipeModal } from "./importOrCreateRecipeModal";

const AddRecipe = (props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState();
  const [showCreate, setShowCreate] = useState();
  const [showCreateIngredient, setShowCreateIngredient] = useState();

  const addStep = (e) => {
    e.preventDefault();
    setFormValues({
      ...formValues,
      steps: [...formValues.steps, { title: "", description: "" }],
    });
  };

  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    yield: 1,
    time: 0,
    steps: [{ title: "", description: "" }],
    ingredients: [],
  });

  const onChange = (values) => {
    if (values.yield <= 1) {
      values.yield = 1;
    }
    if (values.time <= 0) {
      values.time = 0;
    }
    values.time = parseInt(values.time);
    values.yield = parseInt(values.yield);
    setFormValues(values);
  };

  const onSubmit = async ({ value, touched }) => {
    try {
      for (const ingredient of value.ingredients) {
        ingredient.quantity = parseInt(ingredient.quantity);
      }
      const id = await addRecipe(value, token);
      setShow(false);
      navigate(`/recipes/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const onIngredientSelected = (ingredient) => {
    setFormValues({
      ...formValues,
      ingredients: [...formValues.ingredients, ingredient],
    });
  };

  const changeQuantity = (index, change) => {
    const newIngredients = [...formValues.ingredients];
    const newItem = formValues.ingredients[index];
    newItem.quantity = parseInt(newItem.quantity) + change;
    newIngredients[index] = newItem;
    setFormValues({
      ...formValues,
      ingredients: newIngredients,
    });
  };

  const onCreateIngredientClick = () => {
    setShowCreateIngredient(true);
  };

  const onCloseCreateIngredient = (ingredient) => {
    if (ingredient) {
      setFormValues({
        ...formValues,
        ingredients: [...formValues.ingredients, ingredient],
      });
    }
    setShowCreateIngredient(false);
  };

  return (
    <Box {...props}>
      <Button
        label={props.label ?? "Skapa recept"}
        onClick={() => setShow(true)}
      />
      {show && (
        <ImportOrCreateRecipeModal
          onClose={() => setShow(false)}
          onCreate={() => setShowCreate(true)}
        />
      )}
      {showCreateIngredient && (
        <EditIngredientModal onClose={onCloseCreateIngredient} isCreate />
      )}
      {showCreate && (
        <Layer
          onEsc={() => setShowCreate(false)}
          onClickOutside={() => setShowCreate(false)}
          margin="medium"
        >
          <Card background="background-front" pad="medium" overflow="auto">
            <Box direction="row" justify="end">
              <Button
                secondary
                icon={<Close />}
                onClick={() => setShowCreate(false)}
              />
            </Box>
            <Heading margin="small" level="3" as="h1">
              Skapa nytt recept
            </Heading>
            <Form
              validate="blur"
              value={formValues}
              messages={{
                required: "This is a required field.",
              }}
              onChange={(nextValue) => onChange(nextValue)}
              onSubmit={({ value, touched }) => onSubmit({ value, touched })}
              method="post"
            >
              <FormField
                label="Receptnamn"
                htmlFor="recipe-name"
                name="name"
                required={{ indicator: false }}
              >
                <TextInput placeholder="Namn" name="name" />
              </FormField>
              <FormField
                label="Beskrivning"
                htmlFor="recipe-description"
                name="description"
              >
                <TextArea placeholder="Beskrivning" name="description" />
              </FormField>
              <Box direction="row" justify="between">
                <FormField
                  label="Tillagningstid (min)"
                  htmlFor="recipe-duration"
                  name="time"
                  type="number"
                  required={{ indicator: false }}
                  plain="true"
                >
                  <TextInput
                    placeholder="30"
                    name="time"
                    type="number"
                    plain="true"
                  />
                </FormField>
                <FormField
                  label="Portioner"
                  name="yield"
                  htmlFor="recipe-yield"
                  type="number"
                  plain="true"
                  required={{ indicator: false }}
                >
                  <TextInput type="number" name="yield" plain="true" />
                </FormField>
              </Box>
              <Box direction="row">
                <Heading level="4" as="h2">
                  Ingredienser
                </Heading>
              </Box>
              <Box direction="row" align="center" gap="small">
                <IngredientSearchField
                  onIngredientSelected={onIngredientSelected}
                  flex="grow"
                />
                <Button
                  onClick={onCreateIngredientClick}
                  icon={<Add />}
                  label="Skapa ingrediens"
                />
              </Box>
              {formValues.ingredients.map((item, index) => {
                const quantityName = `ingredients[${index}].quantity`;
                return (
                  <Box direction="row">
                    <Box width="100%">
                      <p>
                        {item.name}
                        {item.default_unit?.suffix
                          ? ` (${item.default_unit?.suffix})`
                          : ""}
                      </p>
                    </Box>
                    <Button
                      icon={
                        <FormSubtract
                          onClick={() => {
                            changeQuantity(index, -1);
                          }}
                        />
                      }
                    />
                    <FormField
                      name={quantityName}
                      htmlFor={quantityName}
                      type="number"
                      plain="true"
                    >
                      <TextInput
                        type={"number"}
                        name={quantityName}
                        plain="true"
                      />
                    </FormField>
                    <Button
                      icon={
                        <FormAdd
                          onClick={() => {
                            changeQuantity(index, 1);
                          }}
                        />
                      }
                    />
                  </Box>
                );
              })}
              <Box direction="row">
                <Heading level="4" as="h2">
                  Steg
                </Heading>
                <Button icon={<Add onClick={addStep} />} />
              </Box>
              {formValues.steps.map((item, index) => {
                const descriptionName = `steps[${index}].description`;
                return (
                  <Box
                    direction="row"
                    justify="start"
                    margin="small"
                    key={index}
                  >
                    <Heading level="3" margin="xsmall">
                      {index + 1}
                    </Heading>
                    <Box width="large">
                      <FormField
                        label="Förklaring"
                        htmlFor={descriptionName}
                        name={descriptionName}
                        required={{ indicator: false }}
                      >
                        <TextArea
                          placeholder="Whisk the eggs and..."
                          name={descriptionName}
                        />
                      </FormField>
                    </Box>
                  </Box>
                );
              })}
              <Box direction="row" justify="end" gap="small" margin="small">
                <Button label="Stäng" onClick={() => setShowCreate(false)} />
                <Button label="Spara" type="submit" primary />
              </Box>
            </Form>
          </Card>
        </Layer>
      )}
    </Box>
  );
};

export default AddRecipe;
