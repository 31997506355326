import {
  Anchor,
  Box,
  Grid,
  Heading,
  Paragraph,
  ResponsiveContext,
  Button,
} from "grommet";
import { Clock, ShareRounded, User, Image } from "grommet-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoaderData } from "react-router-dom";
import { Page } from "../shared/Page";
import { IMAGE_TYPES, getImageUrl } from "../util/images";
import Responsive from "../util/responsive";
import IngredientList from "./ingredientList";
import { getRecipe, addImageToRecipe, updateRecipe } from "./service";
import Steps from "./steps";
import { UploadImageModal } from "./uploadImageModal";
import { EditableValue } from "../components/EditableValue";
import { ErrorToast } from "../components/ErrorToast";

export async function loader({ params }) {
  return getRecipe(params.id);
}

const Recipe = (props) => {
  const navigate = useNavigate();
  const recipe = useLoaderData();
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const recipeHasImages = recipe.images && recipe.images.length;
  const [apiError, setApiError] = useState(null);

  const handleImageUpload = async (file) => {
    await addImageToRecipe(recipe.id, file);
    navigate(".", { replace: true });
  };

  const onChange = (field) => async (value) => {
    try {
      await updateRecipe(recipe.id, {
        [field]: value,
      });
      navigate('.', { replace: true });
    } catch (e) {
      setApiError(e.message);
    }
  };

  const onChangeNumber = (field) => async (value) => await onChange(field)(parseInt(value));

  return (
    <Box direction="column" align="center" gap="small" fill>
      <Box
        background={
          recipeHasImages
            ? {
                opacity: true,
                repeat: "no-repeat",
                size: "cover",
                image: `url(${getImageUrl(recipe.images, IMAGE_TYPES.LARGE)})`,
                rotate: 45,
              }
            : "brand"
        }
        align="center"
        backgroundSize="cover"
        fill
      >
        <Page width="xlarge">
          <EditableValue name="titel" placeholder="Mitt recept" value={recipe.name} onChange={onChange("name")}>
            {({ edit }) => (
              <Heading onClick={edit} level="2" as="h1" margin={{bottom: 0}}>{recipe.name}</Heading>
            )}
          </EditableValue>
          {recipe.original_link && (
            <Anchor href={recipe.original_link}>
              <Box direction="row" justify="start" align="center">
                Orginalrecept
                <ShareRounded />
              </Box>
            </Anchor>
          )}
          <ErrorToast error={apiError} onClose={() => setApiError(null)}/>
          <Responsive>
            {({ isPortrait }) => (
              <Box direction={isPortrait ? "column" : "row"} justify="between">
                <EditableValue name="beskrivning" placeholder="Det här receptet är..." value={recipe.description} onChange={onChange("description")} multiline>
                  {({ edit }) => (
                    <Paragraph onClick={edit}>{recipe.description ?? "Ingen beskrivning"}</Paragraph>
                  )}
                </EditableValue>
                <Box alignSelf="end">
                  <Box direction="row" justify="end">
                    <Box pad="small">
                      <EditableValue name="tid" type="number" placeholder="15" value={recipe.time} onChange={onChangeNumber("time")}>
                        {({ edit }) => (
                          <Anchor onClick={edit} icon={<Clock />} label={Math.floor(recipe.time) + "m"} />
                        )}
                      </EditableValue>
                    </Box>
                  </Box>
                  <Box direction="row" justify="end">
                    <Box pad="small">
                      <EditableValue name="portioner" type="number" placeholder="4" value={recipe.yield} onChange={onChangeNumber("yield")}>
                        {({ edit }) => (
                          <Anchor onClick={edit} icon={<User />} label={recipe.yield} />
                        )}
                      </EditableValue>
                    </Box>
                  </Box>
                  <Box direction="row" justify="end">
                    <Box pad="small">
                      <Button
                        as="div"
                        icon={<Image />}
                        label={recipeHasImages ? "Byt bild" : "Ladda upp"}
                        onClick={() => setShowImageUploadModal(true)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Responsive>
        </Page>
      </Box>
      <Box pad={{ bottom: "xlarge" }}>
        <Page>
          <ResponsiveContext.Consumer>
            {(size) => (
              <Grid
                rows={["auto"]}
                columns={
                  ["xsmall", "small", "medium"].includes(size)
                    ? ["1"]
                    : ["medium", "auto"]
                }
                gap="large"
                areas={
                  ["xsmall", "small", "medium"].includes(size)
                    ? [["ingredientList"], ["steps"]]
                    : [["ingredientList", "steps"]]
                }
              >
                <Box gridArea="ingredientList">
                  <IngredientList ingredients={recipe.ingredients} />
                </Box>
                <Box gridArea="steps">
                  <Steps
                    steps={recipe.steps}
                    columns={
                      {
                        xsmall: ["1fr"],
                        small: ["1fr"],
                        medium: ["1fr", "1fr"],
                        large: ["1fr"],
                        xlarge: ["1fr", "1fr"],
                        xxlarge: ["1fr", "1fr", "1fr"],
                      }[size]
                    }
                  />
                </Box>
              </Grid>
            )}
          </ResponsiveContext.Consumer>
        </Page>
      </Box>
      {showImageUploadModal && (
        <UploadImageModal
          onClose={() => setShowImageUploadModal(false)}
          onClick={handleImageUpload}
        />
      )}
    </Box>
  );
};

export default Recipe;
