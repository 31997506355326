import { Box, Button, Layer, Nav, ResponsiveContext, Text } from "grommet";
import { Close, Configure, List, Login, Menu as MenuIcon, Restaurant, User } from "grommet-icons";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import LogoutBtn from "../auth/logout";
import { NavLink } from "./NavLink";
import { GurkanIcon } from "../shared/GurkanIcon";
import theme from "../theme";
import { useUserData } from "../auth/useUserData";
import { useSessionData } from "../auth/useSessionData";

export const NavbarMenu = () => {
  const { email } = useUserData();
  const { isValid: isLoggedIn, user } = useSessionData();
  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = useLocation();

  return (
    <ResponsiveContext.Consumer>
      {(responsive) => (
        <Box direction="row" flex={{ shrink: 0 }} style={{ position: "relative" }}>
          <Box justify="center">
            <Button
              icon={<MenuIcon color={theme.global.colors['accent-1']} />}
              onClick={() => setShowMenu(true)}
              alignContent="center"
            />
          </Box>
          {showMenu && (
            <Layer
              onEsc={() => setShowMenu(false)}
              onClickOutside={() => setShowMenu(false)}
              full={['xsmall', 'small'].includes(responsive) ? true : "vertical"}
              position="left"
              animation="slide"
              background="none"
            >
              <Box
                background="brand"
                alignSelf="start"
                fill={['xsmall', 'small'].includes(responsive) ? true : "vertical"}
                pad="medium"
              >
                <Box display="flex" direction="column" align="center">
                  <Button
                    icon={<Close color={theme.global.colors['accent-1']} />}
                    onClick={() => setShowMenu(false)}
                    margin={{'bottom': 'medium'}}
                  />
                </Box>
                <Nav>
                  <NavLink
                    href="/"
                    icon={<GurkanIcon />}
                    label="Gurkan"
                    alignContent="center"
                    hoverIndicator
                  />
                  {isLoggedIn && (
                    <NavLink
                      href="/recipes"
                      icon={<Restaurant />}
                      label="Recept"
                      alignContent="center"
                      hoverIndicator
                    />
                  )}
                  {isLoggedIn && (
                    <NavLink
                      href="/shoppinglists"
                      icon={<List />}
                      label="Inköpslistor"
                      alignContent="center"
                      hoverIndicator
                    />
                  )}
                  {isLoggedIn && (
                    <Box direction="row" align="center" pad="12px" gap="12px" fill style={{ borderTop: "1px solid", borderColor: theme.global.colors['accent-1'] }}>
                      <User color="accent-1" />
                      <Text truncate size="small" weight="bold" style={{ maxWidth: "200px" }} color="accent-1">
                        {email}
                      </Text>
                    </Box>
                  )}
                  {isLoggedIn && user?.is_admin ? (
                    <NavLink
                      href="/admin"
                      icon={<Configure />}
                      label="Admin"
                      alignContent="center"
                      hoverIndicator
                    />
                  ) : null}
                  {isLoggedIn && (
                    <LogoutBtn />
                  )}
                  {isLoggedIn && (
                    <NavLink
                      href="/profile"
                      icon={<User />}
                      label="Profil"
                      hoverIndicator
                    />
                  )}
                  {!isLoggedIn && pathname !== '/login' && (
                    <NavLink
                      href="/login"
                      icon={<Login color={theme.global.colors['accent-1']} />}
                      label="Logga in"
                      hoverIndicator
                    />
                  )}
                </Nav>
              </Box>
            </Layer>
          )}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};