import { Nav, Header, Box, Button } from "grommet";
import { Restaurant, List } from "grommet-icons";
import { GurkanIcon } from "../shared/GurkanIcon";
import { Outlet } from "react-router-dom";
import { useSessionData } from "../auth/useSessionData";
import { NavbarMenu } from "./NavbarMenu";
import { NavLink } from "./NavLink";
import { NavbarAccount } from "./NavbarAccount";
import { Configure } from "grommet-icons";
import Responsive from "../util/responsive";

export default function Navbar() {
  const { isValid: isLoggedIn, user } = useSessionData();

  return (
    <Box align="center">
      <Header fill="horizontal" background="brand" width="xlarge" pad={{'horizontal': 'medium'}} style={{ position: "relative" }}>
        <Responsive>
          {({ isPortrait }) => isPortrait ? (
            <>
              <Box direction="row" align="center" justify="center" style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}>
                <Button href="/" style={{ display: "flex" }}>
                  <GurkanIcon />
                </Button>
              </Box>
              <NavbarMenu />
            </>
          ) : (
            <Nav direction="row" align="center">
              <NavLink
                href="/"
                icon={<GurkanIcon />}
                label="Gurkan"
                alignContent="center"
                hoverIndicator
              />
              {isLoggedIn && (
                <NavLink
                  href="/recipes"
                  icon={<Restaurant />}
                  label="Recept"
                  alignContent="center"
                  hoverIndicator
                />
              )}
              {isLoggedIn && (
                <NavLink
                  href="/shoppinglists"
                  icon={<List />}
                  label="Inköpslistor"
                  alignContent="center"
                  hoverIndicator
                />
              )}
              {isLoggedIn && user?.is_admin ? (
                <NavLink
                  href="/admin"
                  icon={<Configure />}
                  label="Admin"
                  alignContent="center"
                  hoverIndicator
                />
              ) : null}
            </Nav>
          )}
        </Responsive>
        <Nav direction="row" align="center" flex="1">
          <Responsive>
            {({ isPortrait }) => isLoggedIn && !isPortrait && (
              <NavbarAccount />
            )}
          </Responsive>
        </Nav>
      </Header>
      <Outlet />
    </Box>
  );
}
