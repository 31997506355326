import React, { useEffect } from "react";
import { Box, Button, Card, Form, FormField, Heading, Layer, TextArea, TextInput } from "grommet";
import { useState } from "react";
import styled from "styled-components";

const EditableSpan = styled.span`
  cursor: pointer;
  transition: box-shadow 0.2s;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }
`;

export const EditableValue = ({ name, value, placeholder, children, onChange, type = "string", multiline = false }) => {
  const [showModal, setShowModal] = useState(false);
  const [values, setValues] = useState({
    value: value,
  });

  useEffect(() => {
    setValues({
      value: value,
    });
  }, [value]);

  const edit = () => {
    setShowModal(true);
  }

  const onClose = () => {
    setShowModal(false);
  }

  const onFormChange = (nextValues) => {
    setValues(nextValues);
  }

  const onSubmit = ({ touched }) => {
    setShowModal(false);
    if (touched.value) {
      onChange(values.value);
    }
  }

  return (
    <EditableSpan>
      {showModal && (
        <Layer
          onEsc={onClose}
          onClickOutside={onClose}
          margin="medium"
        >
          <Card background="background-front" pad="medium" overflow="auto">
            <Heading margin="small" level="3" as="h1">
              Redigera {name}
            </Heading>
            <Form
              validate="blur"
              value={values}
              messages={{
                required: "This is a required field.",
              }}
              onChange={onFormChange}
              onSubmit={onSubmit}
              method="post"
            >
              <FormField
                label={name}
                name="value"
                type={type}
                required={{ indicator: false }}
                plain="true"
                style={{ textTransform: "capitalize" }}
              >
                {multiline ? (
                  <TextArea placeholder={placeholder} name="value" type={type} plain="true" autoFocus />
                ) : (
                  <TextInput placeholder={placeholder} name="value" type={type} plain="true" autoFocus />
                )}
              </FormField>
              <Box direction="row" justify="end" gap="small" margin="small">
                <Button
                  label="Stäng"
                  onClick={() => setShowModal(false)}
                />
                <Button label="Spara" type="submit" primary />
              </Box>
            </Form>
          </Card>
        </Layer>
      )}
      {children({ edit })}
    </EditableSpan>
  );
}