import { Notification } from "grommet";
import { useEffect, useState } from "react";

export const ErrorToast = ({ error, onClose }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (error) {
      setShow(true);
    }
  }, [error]);

  return show && (
    <Notification
      toast
      title="Error"
      status="critical"
      message={error}
      onClose={onClose}
    />
  );
};
