import { useState } from "react";

import {
  Box,
  Button,
  Layer,
  Form,
  FormField,
  FileInput,
  Spinner,
  Text,
} from "grommet";

export const UploadImageModal = (props) => {
  const maxFileSize = 5000000;
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  const onImageUpload = async () => {
    setUploading(true);
    await props.onClick(files);
    setUploading(false);
    props.onClose();
  };

  const handleOnChange = (event, { files }) => {
    if (!files || files.length === 0) {
      setFiles([]);
      return;
    }
    const _file = files[0];
    if (
      _file.type !== "image/jpeg" &&
      _file.type !== "image/png" &&
      _file.size > maxFileSize
    ) {
      alert("Endast jpg och png filer är tillåtna med max storlek 5mb");
      setFiles([]);
      return;
    }
    setFiles([_file]);
  };

  return (
    <Layer
      onEsc={() => props.onClose()}
      onClickOutside={() => props.onClose()}
      background="background-front"
    >
      <Box pad="medium" gap="medium">
        <Box fill align="center" justify="start" pad="large">
          <Box width="medium">
            {uploading ? (
              <Box align="center" gap="small">
                <Spinner
                  border={[
                    { side: "all", color: "transparent", size: "large" },
                    { side: "horizontal", color: "accent-1", size: "large" },
                  ]}
                />
                <Text>Laddar upp...</Text>
              </Box>
            ) : (
              <Form onSubmit={onImageUpload}>
                <FormField
                  label="Välj bild"
                  name="file-input"
                  htmlFor="file-input"
                >
                  <FileInput
                    name="file-input"
                    id="file-input"
                    multiple={false}
                    maxSize={maxFileSize}
                    value={files}
                    onChange={handleOnChange}
                    confirmRemove={({ onConfirm, onCancel }) => (
                      <Layer onClickOutside={onCancel} onEsc={onCancel}>
                        <Box pad="medium" gap="medium">
                          Är du säker på att du vill ta bort den här filen?
                          <Box
                            direction="row"
                            align="center"
                            justify="end"
                            gap="small"
                          >
                            <Button label="Cancel" onClick={onCancel} />
                            <Button
                              label="Ta bort fil"
                              onClick={onConfirm}
                              primary
                            />
                          </Box>
                        </Box>
                      </Layer>
                    )}
                  />
                </FormField>
                <Button label="Ladda upp" type="submit" />
              </Form>
            )}
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};
