import { BASE_URL, CommonHeaders } from "../util/service";

const addImageToRecipe = async (recipeId, image) => {
  const formData = new FormData();
  formData.append("file", image[0]);

  var headers = CommonHeaders();
  delete headers["Content-Type"]; // Remove the Content-Type header

  const rawRes = await fetch(BASE_URL + "recipe/" + recipeId + "/image", {
    method: "POST",
    headers: headers,
    body: formData,
  });

  const res = await rawRes.json();
  return res;
};

const getRecipe = async (id) => {
  const recipe = await fetch(BASE_URL + "recipe/" + id, {
    method: "GET",
    headers: CommonHeaders(),
  }).then((resp) => resp.json());
  return recipe;
};

const getRecipes = async () => {
  const req = await fetch(BASE_URL + "recipe", {
    method: "GET",
    headers: CommonHeaders(),
  }).then((resp) => resp.json());
  return req;
};

const addRecipe = async (recipe) => {
  recipe.allergens = [];
  try {
    const rawRes = await fetch(BASE_URL + "recipe", {
      method: "POST",
      headers: CommonHeaders(),
      body: JSON.stringify(recipe),
    });
    const res = await rawRes.json();

    const stepsPromises = recipe.steps.map((step, i) => {
      return fetch(BASE_URL + "step", {
        method: "POST",
        headers: CommonHeaders(),
        body: JSON.stringify({
          recipeId: res.id,
          order: i,
          title: step.title,
          description: step.description,
          image: "",
        }),
      });
    });

    const ingredientPromises = recipe.ingredients.map((ingredient) => {
      return fetch(BASE_URL + "recipe/add_ingredient", {
        method: "POST",
        headers: CommonHeaders(),
        body: JSON.stringify({
          recipeId: res.id,
          ingredientId: ingredient.id,
          quantity: ingredient.quantity,
          unitId: ingredient.default_unit.id,
        }),
      });
    });

    const promiseRes = await Promise.all([
      ...stepsPromises,
      ...ingredientPromises,
    ]);
    for (const res of promiseRes) {
      if (res.status !== 200 && res.status !== 204) {
        throw new Error("Failed to create recipe", res);
      }
    }
    return res.id;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateRecipe = async (id, body) => {
  const rawRes = await fetch(BASE_URL + "recipe/" + id, {
    method: "PUT",
    headers: CommonHeaders(),
    body: JSON.stringify(body),
  });
  const res = await rawRes.json();
  if (rawRes.status !== 200) {
    throw new Error(res?.message ?? 'Unknown error');
  }
  return res;
};

const searchIngredient = async (search, threshhold) => {
  const rawRes = await fetch(BASE_URL + "ingredient/search", {
    method: "POST",
    headers: CommonHeaders(),
    body: JSON.stringify({
      search: search,
      threshhold: threshhold,
    }),
  });
  const res = await rawRes.json();
  return res;
};

const importRecipeFromURL = async ({ url }) => {
  try {
    const rawRes = await fetch(BASE_URL + "recipe/import", {
      method: "POST",
      headers: CommonHeaders(),
      body: JSON.stringify({ url }),
    });
    const res = await rawRes.json();

    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export {
  getRecipe,
  getRecipes,
  addRecipe,
  updateRecipe,
  searchIngredient,
  importRecipeFromURL,
  addImageToRecipe,
};
