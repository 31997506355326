import React, { useContext, useEffect, useState } from "react";
import { getRecipes } from "./service";
import { Page } from "../shared/Page";
import {
  Anchor,
  Box,
  Card,
  CardBody,
  Grid,
  ResponsiveContext,
  Text,
  ThemeContext,
} from "grommet";
import { IMAGE_TYPES, getImageUrl } from "../util/images";
import AddRecipe from "./addRecipeModal";

const RecipeList = (props) => {
  const { dark } = useContext(ThemeContext);
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    getRecipes().then((resp) => setRecipes(resp));
  }, []);

  return (
    <Page>
      <ResponsiveContext.Consumer>
        {(size) => (
          <>
            <Box
              direction={["xsmall", "small"].includes(size) ? "column" : "row"}
              gap="small"
              align={["xsmall", "small"].includes(size) ? "start" : "center"}
              margin={{
                bottom: ["xsmall", "small"].includes(size) ? "large" : "",
              }}
            >
              <h1>Recept</h1>
              <AddRecipe />
            </Box>
            <Grid
              columns={
                {
                  xsmall: ["1fr"],
                  small: ["1fr"],
                  medium: ["1fr", "1fr"],
                  large: ["1fr", "1fr", "1fr"],
                  xlarge: ["1fr", "1fr", "1fr", "1fr"],
                  xxlarge: ["1fr", "1fr", "1fr", "1fr"],
                }[size]
              }
              gap="small"
            >
              {recipes.map((element) => {
                return (
                  <Anchor href={`/recipes/${element.id}`} key={element.id}>
                    <Card background="background-contrast" height="100%">
                      <CardBody>
                        <Grid
                          columns={
                            ["xsmall", "small"].includes(size)
                              ? ["2fr", "1fr"]
                              : ["1fr"]
                          }
                          rows={
                            ["xsmall", "small"].includes(size)
                              ? ["1fr"]
                              : ["auto", "auto"]
                          }
                        >
                          <img
                            src={getImageUrl(element.images, IMAGE_TYPES.LARGE)}
                            alt={element.name}
                            style={{
                              width: "100%",
                              aspectRatio: ["xsmall", "small"].includes(size)
                                ? "1/1"
                                : "2/1",
                              objectFit: "cover",
                              order: ["xsmall", "small"].includes(size)
                                ? 2
                                : "",
                              filter:
                                dark && !element.images.length
                                  ? "brightness(0.5)"
                                  : "",
                            }}
                          />
                          <Box pad="medium">
                            <Text
                              size="medium"
                              color="text"
                              className="truncate-3"
                            >
                              {element.name}
                            </Text>
                          </Box>
                        </Grid>
                      </CardBody>
                    </Card>
                  </Anchor>
                );
              })}
            </Grid>
          </>
        )}
      </ResponsiveContext.Consumer>
    </Page>
  );
};

export default RecipeList;
